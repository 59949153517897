import { Spinner } from "react-bootstrap";
import * as React from "react";
import cornerstone from 'cornerstone-core';
import initCornerstone from "../../initCornerstone";

export const DisplaySeries = (props: any) => {
    const [loading, setLoading] = React.useState(true);
    const [loadedImage, setLoadedImages] = React.useState(0);

    React.useEffect(() => {
        initCornerstone();
        let count = 0;
        Promise.all(props.imageIds.map(async (imageUrl: any) => {
            const image = await cornerstone.loadAndCacheImage(imageUrl);
            count += 1;
            setLoadedImages(count);
            return image;
        })).then(() => {
            setLoading(false);
        }).catch((e) => {
            console.log(e);
        });
    }, []);

    if (loading) {
        return <span> {loadedImage} / {props.imageIds.length} </span>
    }

    return <span>{props.seriesName}</span>
}

