import React from 'react';
import './App.css';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Showxray from './pages/Showxray';
import Appcontextprovider from './Appcontextprovider';
import ShowCathLab from './pages/ShowCathLab';
import CathLabGridLayout from './pages/CathLabGridLayout';
import XrayViews from './pages/XrayViews';
import Sharexray from './pages/Sharexray';

function App() {


  return (
    <Appcontextprovider>
      <Router>
        <Routes>
          <Route path="/cathlab/:centerid/:instanceid" element={<ShowCathLab />} />
          <Route path="/ultra-sound/:centerid/:instanceid" element={<ShowCathLab />} />
          <Route path="/showxray" element={<Showxray />} />
          <Route path="/mobile-cathlab-view/:centerid/:instanceid" element={<CathLabGridLayout />}  />
          <Route path="/sharable-xray/:centerid/:instanceid" element={<Sharexray />} />
          <Route path="/xrayview/:centerid/:instanceid" element={<XrayViews />} />
        </Routes>
      </Router>
    </Appcontextprovider>
  );
}


export default App;
