

export const Radiology = {
    context: 'radiology',
    events: {
        GET_ALL_RADIOLOGY_LIST: 'get_all_radiology_list',
        GET_FILTERED_RADIOLOGY_LIST: 'get_filtered_radiology_list',
        UPDATE_TAGS: 'update_image_tags',
        REMOVE_TAG: 'remove_tag',
        MAP_UHID_TO_PATIENT: 'map_uhid_to_patient',
        PATIENT_LEVEL_RADIOLOGY: 'patient_level_radiology',
        ASSIGN_STUDY: 'assign_study',
        UN_ASSIGN_STUDY: 'un_assign_study',
        SET_MODALITY: 'set_modality',
    },
    errors: {
    }
};

export function isMobile() {
    const toMatch = [
      /Android/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    
  
    return toMatch.some(toMatchItem => {
      return navigator.userAgent.match(toMatchItem);
    });
}

export function isAndroid() {
    const toMatch = [
      /Android/i,
    ];

    return toMatch.some(toMatchItem => {
      return navigator.userAgent.match(toMatchItem);
    });
}


export const DcmViewerTools = [
    {
        name: "Length",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
        displayName: "Measurement",
        id: "measurement",
    },
    {
        name: "Angle",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
        displayName: "Angle",
        id: "angle",
    },
    {
        name: "CobbAngle",
        mode: "active",
        mouseButtonMask: 1,
        displayName: "Cobb Angle",
        id: "cobb",
    },
    {
        name: "Rotate",
        mode: "active",
        mouseButtonMask: 1,
        displayName: "Rotate",
        id: "rotate",
    },
    {
        name: "Bidirectional",
        displayName: "Bidirectional",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
        id: "bidirectional",
    },
    {
        name: "Wwwc",
        displayName: "Wwwc",
        mode: "active",
        id: "wwwc",
        modeOptions: { mouseButtonMask: 1 },
    },
    {
        name: "Pan",
        displayName: "Pan",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
        id: "pan",
    },
    {
        name: "StackScrollMouseWheel",
        id: "stackScroll",
        mode: "active",
        displayName: "Stack Scroll",
        modeOptions: {
            mouseButtonMask: 1,
        },
    },
    {
        name: "ZoomMouseWheel",
        id: "zoom",
        mode: "active",
        displayName: "Zoom Wheel",
          modeOptions: {
            mouseButtonMask: 1,
          },
    },
    /* {
         name: "Layout",
         id: "layout",
         mode: "active",
         displayName: "Layout",
         modeOptions: {
             mouseButtonMask: 1,
         },
    }, */
    {
        name: "Eraser",
        displayName: "Eraser",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
        id: "eraser",
    },
    {
        viewportAction: 'invert',
        name: 'Invert',
        displayName: 'Invert',
        id: 'invert',
    },
    {
        viewportAction: "vflip",
        name: "V-Flip",
        mode: "active",
        mouseButtonMask: 1,
        displayName: "V Flip",
        id: "v-flip",
    },
    {
        viewportAction: "hflip",
        name: "H-Flip",
        displayName: "H Flip",
        id: "h-flip",
    },
    {
        name: "Pan",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
    },
    "Angle",
    "FreehandRoi",
    "Eraser",
    "StackScrollMouseWheel",
    { name: "StackScrollMouseWheel", mode: "active" },
    { name: "PanMultiTouch", mode: "active" },
    { name: "ZoomTouchPinch", mode: "active" },
    {name: "ArrowAnnotate", mouseButtonMask: 1,displayName: "Annotate", id:"arrowannotate"},
    {name: "Probe", mouseButtonMask: 1,displayName: "Probe", id:"probetool"},
    {
        viewportAction: "download",
        name: "Download",
        displayName: "Download",
        id: "download",
    },
    // { name: "StackScrollMultiTouch", mode: "active" },
] as any;


export const PortraitTools = [
    {
        name: "ZoomMouseWheel",
        id: "zoom",
        mode: "active",
        displayName: "Zoom Wheel",
          modeOptions: {
            mouseButtonMask: 1,
          },
    },
    {
        name: "Wwwc",
        displayName: "Wwwc",
        mode: "active",
        id: "wwwc",
        modeOptions: { mouseButtonMask: 1 },
    },
    {
        name: "Length",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
        displayName: "Measurement",
        id: "measurement",
    },
    {name: "Probe", mouseButtonMask: 1,displayName: "Probe", id:"probetool"},
    { name: "ZoomTouchPinch", mode: "active" },
    // { name: "StackScrollMultiTouch", mode: "active" },
] as any;

export const DcmViewerToolsMobile = [

    {
        name: "Angle",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
        displayName: "Angle",
        id: "angle",
    },
    {
        name: "CobbAngle",
        mode: "active",
        mouseButtonMask: 1,
        displayName: "Cobb Angle",
        id: "cobb",
    },
    {
        name: "Rotate",
        mode: "active",
        mouseButtonMask: 1,
        displayName: "Rotate",
        id: "rotate",
    },
    {
        name: "Bidirectional",
        displayName: "Bidirectional",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
        id: "bidirectional",
    },
    {
        name: "Pan",
        displayName: "Pan",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
        id: "pan",
    },
    {
        name: "StackScrollMouseWheel",
        id: "stackScroll",
        mode: "active",
        displayName: "Stack Scroll",
        modeOptions: {
            mouseButtonMask: 1,
        },
    },
    {
        viewportAction: 'invert',
        name: 'Invert',
        displayName: 'Invert',
        id: 'invert',
    },
    {
        viewportAction: "vflip",
        name: "V-Flip",
        mode: "active",
        mouseButtonMask: 1,
        displayName: "V Flip",
        id: "v-flip",
    },
    {
        viewportAction: "hflip",
        name: "H-Flip",
        displayName: "H Flip",
        id: "h-flip",
    },
    {
        name: "Pan",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
    },
    "Angle",
    "FreehandRoi",
    "Eraser",
    "StackScrollMouseWheel",
    { name: "StackScrollMouseWheel", mode: "active" },
    { name: "PanMultiTouch", mode: "active" },
    { name: "ZoomTouchPinch", mode: "active" },
    {name: "ArrowAnnotate", mouseButtonMask: 1,displayName: "Annotate", id:"arrowannotate"},
    {
        viewportAction: "download",
        name: "Download",
        displayName: "Download",
        id: "download",
    },
    {
        name: "Eraser",
        displayName: "Eraser",
        mode: "active",
        modeOptions: { mouseButtonMask: 1 },
        id: "eraser",
    },
    // { name: "StackScrollMultiTouch", mode: "active" },
] as any;