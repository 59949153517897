import React, { useEffect } from "react";
import CornerstoneViewport from "react-cornerstone-viewport";
import { useState } from "react";
import { SpinnerLoader } from "./SpinnerLoader";
import cornerstone from "cornerstone-core";
import { DcmViewerTools, isMobile } from "../constants/Radiology";
import cornerstoneTools from "cornerstone-tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useMobileOrientation } from 'react-device-detect';


const XrayViewPort = (props: any) => {
    const { images, activeTool, actionTool, setActionTool, fullView, handleChangeImageClick, noOfImages, viewportSize, fromReporting
} = props;
    const [loading, setLoading] = useState(true);
    const [cornerStonestate, setCornerStoneState] = useState(null as any);
    const { isPortrait,isLandscape } = useMobileOrientation()
    
    useEffect(() => {
        setTimeout(() => setLoading(false), 50)
    }, []);

    useEffect(() => {
        if (cornerStonestate?.cornerstoneElement && actionTool) {
            const { viewport, cornerstoneElement } = cornerStonestate;
            const updatedViewport = Object.assign({}, viewport, {
                [actionTool]: !viewport[actionTool],
            });

            setCornerStoneState({ cornerstoneElement, viewport: updatedViewport });

            cornerstone.setViewport(
                cornerstoneElement,
                updatedViewport
            );

            setActionTool('');
        }
    }, [actionTool]);




    const state = {
        activeViewportIndex: 0,
        viewports: [0],
        imageIds: images,
    };

    if (loading) {
        return <div style={{ width: '100vw', height: '100vh', backgroundColor: '#000' }}>  <div style={{ padding: '20px' }}> <SpinnerLoader message={'Loading x-ray files, please wait ...'} />; </div></div>;
    }
 
    if (fullView) {
        return <div style={{display:  'flex', flexDirection: 'row'}}>
            <div style={{ width: noOfImages > 1 ? '8%' : '0%', height: '100vh'}}>
                <div
                    className="prev"
                    style={{ height: '100%', width: '100%', textAlign: 'center', color: '#FFF', backgroundColor: '#081a2f', borderRadius: '0px 0px 8px 8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    onClick={() => handleChangeImageClick("next")}
                >
                <FontAwesomeIcon icon={faAngleLeft} size="2x" />
                </div>
            </div>
            <div style={{ width: noOfImages > 1 ? '84%' : '100%', height:   '95vh' }}>
                <CornerstoneViewport
                    key={0}
                    imageIdIndex={0}
                    isOverlayVisible={false}
                    activeTool={activeTool}
                    frameRate={22}
                    tools={DcmViewerTools}
                    imageIds={state.imageIds}
                    style={{ width: '100%', height: fromReporting ?  '70vh' : '95vh', color: '#FFF' }}
                    onElementEnabled={(elementEnabledEvt: any) => {
                        const cornerstoneElement = elementEnabledEvt.detail.element;
                        // cornerstoneTools.addStackStateManager(cornerstoneElement, ['stack']);
                        // setState(Object.assign({}, state, { cornerstoneElement }));
                        let viewport = "";
                        cornerstoneElement.addEventListener(
                            "cornerstonenewimage",
                            (imageRenderedEvent: any) => {
                                viewport = imageRenderedEvent.detail.viewport;
                                setCornerStoneState({ cornerstoneElement, viewport });
                                cornerstone.setViewport(cornerstoneElement, viewport);
                            }
                        );
                    }}
                />
            </div>

            <div style={{ width: noOfImages > 1 ? '8%' : '0%', height: '100vh' }}>
                <div

                    className="prev"
                    style={{ height: '100%', width: '100%', textAlign: 'center', color: '#FFF', backgroundColor: '#081a2f', borderRadius: '0px 0px 8px 8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    onClick={() => handleChangeImageClick("next")}
                >
                 <FontAwesomeIcon icon={faAngleRight} size="2x" />
                </div>
            </div>

            


        </div>
    }
    
    
    return <CornerstoneViewport
        key={0}
        imageIdIndex={0}
        isOverlayVisible={true}
        activeTool={activeTool}
        frameRate={22}
        tools={DcmViewerTools}
        imageIds={state.imageIds}
        style={{ fontSize: isMobile() && isPortrait ? 11 : 15 ,minWidth: '100%', height: viewportSize ? viewportSize : '100vh', flex: '1', color: '#FFF' }}
        onElementEnabled={(elementEnabledEvt: any) => {
            const cornerstoneElement = elementEnabledEvt.detail.element;
            // cornerstoneTools.addStackStateManager(cornerstoneElement, ['stack']);
            // setState(Object.assign({}, state, { cornerstoneElement }));
            let viewport = "";
            cornerstoneElement.addEventListener(
                "cornerstonenewimage",
                (imageRenderedEvent: any) => {
                    viewport = imageRenderedEvent.detail.viewport;
                    setCornerStoneState({ cornerstoneElement, viewport });
                    cornerstone.setViewport(cornerstoneElement, viewport);
                }
            );
        }}
    />
    
}

export default XrayViewPort
