import Hammer from "hammerjs";
import dicomParser from "dicom-parser";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import cornerstone from "cornerstone-core";
import cornerstoneMath from "cornerstone-math";
import cornerstoneTools from "cornerstone-tools";

export default function initCornerstone() {
  // Cornerstone Tools
  cornerstoneTools.external.cornerstone = cornerstone;
  cornerstoneTools.external.Hammer = Hammer;
  cornerstoneTools.external.cornerstoneMath = cornerstoneMath;

  cornerstoneTools.init({
    showSVGCursors: false,
    touchEnabled: true,
    mouseEnabled: true,
  });

  const fontFamily = "Poppins, sans-serif";

  cornerstoneTools.textStyle.setFont(`18px ${fontFamily}`);

  // Set the tool width
  cornerstoneTools.toolStyle.setToolWidth(2);


  // Set color for inactive tools
  cornerstoneTools.toolColors.setToolColor("rgb(255, 255, 0)");

  // Image Loader
  cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
  cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
  (cornerstoneWADOImageLoader as any).webWorkerManager.initialize({
    maxWebWorkers: navigator.hardwareConcurrency || 8,
    startWebWorkersOnDemand: false,
    taskConfiguration: {
      decodeTask: {
        initializeCodecsOnStartup: false,
        usePDFJS: false,
        quality: 100,
        strict: true,
      },
    },
  });
}
