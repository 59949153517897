/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Col, Container, Nav, Navbar, NavDropdown, Row, Spinner } from 'react-bootstrap'
import XrayViewPort from '../components/XrayViewPort';
import VerticalCarousel from '../components/VerticalCurosal';
import "../styles/Left-carousel.css";

import cornerstone from "cornerstone-core";
import initCornerstone from '../initCornerstone';
import { SpinnerLoader } from '../components/SpinnerLoader';
import axios from 'axios';
import { useParams } from 'react-router';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import ListSeries from '../components/Radiology/ListSeries';
import { LinearProgress } from '@mui/material';
import { XrayImageLoader } from '../components/Radiology/XrayImageLoader';
import { HoritontalTools } from '../components/Radiology/HoritontalTools';
import canvasToImage from "canvas-to-image";
const imgSrc1 = 'wadouri:https://dkb1p83dn9zja.cloudfront.net/8ada5da1-7808-41a2-9632-d647ba48619a/1.2.392.200036.9107.307.30301.113030122011901629/1.2.392.200036.9107.307.30301.20220119.93940.1007762.dcm';
const imgSrc2 = 'wadouri:https://dkb1p83dn9zja.cloudfront.net/8ada5da1-7808-41a2-9632-d647ba48619a/1.2.392.200036.9107.307.30301.113030122011901629/1.2.392.200036.9107.307.30301.20220119.93941.1007763.dcm';
const imageListSrc = [imgSrc1, imgSrc2];


const XrayViews = (props: any) => {
    const fullView = window.location.href.indexOf('showxray-fullview')>-1;
    const [activeIndex, setActiveIndex] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [imageList, setImageList] = useState([] as any);

    const [activeTool, setActiveTool] = useState('Wwwc');
    // eg. vflip
    const locationObj = useParams();
    const {centerid, instanceid}: any = locationObj;
    const [actionTool, setActionTool] = React.useState('');
    const images: string[] = [];
    const [activeSeries, setActiveSeries] = useState(0);
    const [prefetch,SetPrefetch] = useState(false);
    const [viewportSize] = useState('85vh');
    const fromReporting = window.location.href?.indexOf('?report=true') > -1;
    
    
    const downloadImage = (class_name: string, type:string, fileName:string)=>{
        const element = document.getElementsByClassName(
            class_name
          )[
            document.getElementsByClassName(class_name)
              .length - 1
          ];
        //console.log("It's here",type, element)
          if (type == 'png'){
            canvasToImage(element.children[0], {
                name: fileName,
                type: "png",
                quality: 1,
              });
        }
    }
    
    const LeftContainerStyle = {
        height: '85vh',
        width: '98%',
        background: '#000',
        padding: '10px',
        overflow: 'scroll',
        paddingBottom:'50px',
    }

    useEffect(()=>{
        SetPrefetch(false)
        initCornerstone();
        try {
            (async ()=> {
            const options = {
                onUploadProgress: (progressEvent: any) => {
                    const { loaded, total } = progressEvent;
                    const percent = Math.floor((loaded * 100) / total);
            
                    if (percent <= 100) {
                    setPercentage(percent);
                    }
                }
                };
            let studyHash = instanceid?.replaceAll('###', '/');
            studyHash = studyHash.replaceAll('%20', ' ');
            const obj = await axios.post('https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod/radiology-studies/by-hash/' + centerid,{
                studyHash
            }) ; 
            const data = obj.data;
            //console.log(data);
            const listOfImages = data.filteredList[0].urls;
            
            const compressedImagesList = listOfImages.map((image: any)=>{
                return image.compressedUrl;
            })
            setImageList([...compressedImagesList]);
            setLoading(false);
            Promise.all(listOfImages.map(async (imageUrl: any) => {
                const image = await cornerstone.loadAndCacheImage(imageUrl.url);
                images.push(imageUrl.url);
            })).then(() => {
                setImageList([...images]);
                //console.log(images);
                setLoading(false);
            }).catch((e) => {
                console.log(e);
            });
            }
            )()
        } 
        catch(e){
            console.log(e);
        }
    }, [])

    cornerstone.events.addEventListener('cornerstoneimageloadprogress', function(event: any) {
        const eventData = event.detail;
        console.log("eventdata",eventData.percentComplete);
        setPercentage(eventData.percentComplete);
    });
    
    const handleChangeImageClick = (direction: any) => {
        setActiveIndex((prevIndex: any) => {
            if (direction === "next") {
                if (prevIndex + 1 > imageList.length - 1) {
                    return 0;
                }
                return prevIndex + 1;
            }

            if (prevIndex - 1 < 0) {
                return imageList.length - 1;
            }

            return prevIndex - 1;
        });
    };

    if (loading) {
        return <div style={{ width: '100vw', height: '100vh', backgroundColor: '#000' }}>  <div style={{ padding: '20px' }}> <SpinnerLoader message={'Loading x-ray files, please wait ...'} />; </div></div>;
    }

    return (
    <div style={{height:'100vh',overflow:'hidden'}}>
        <DndProvider backend={HTML5Backend}  >
            <Navbar>
                <Container style={{height:'27px',}}>
                    <Navbar.Brand >
                        <img src='https://pdf-logo.s3.ap-south-1.amazonaws.com/Bewell-radiology-logo-blue.png'
                            style={{marginTop:-5,marginLeft:'1.1vw',maxHeight:'25px',maxWidth:'100%'}}
                        /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                </Container>
            </Navbar>
            <div style={{background:'#000' ,}}>
             <Row style={{borderBottom: '1px solid #5F5F5F',width:'100%',}}>
                    <div style={{ backgroundColor: 'black', maxWidth: '98vw', flexDirection: 'row',color:'#fff',}}>
                            <HoritontalTools
                                fromReporting={fromReporting}
                                layout={1}
                                setLayout={(layout: number) => {
                                    // setLayout(layout);
                                    // setTimeout(() => {
                                    //     setState(Object.assign({}, state, { activeTool: 'StackScrollMouseWheel' }));
                                    // }, 10);
                                }}
                                activeTool={activeTool}
                                selectActiveTool={(activeTool: string) => {
                                    setActiveTool(activeTool);
                                }}
                                viewportAction={(actionProperty: string, imgType?: string) => {
                                    setActionTool(actionProperty);
                                    // console.log("Action Property", actionProperty);
                                    
                                }}
                                downloadImage={downloadImage}
                            />
                </div>
            </Row>

            <Row >
                <Col xs={2} style={{borderRight: '1px solid #5F5F5F',}}>
                            {fromReporting ? null:  <div style={{background:'#201c1c',width:'100%',height:'7vh',display:'flex',alignItems: 'center',}}>
                        <div style={{color:'#FFF',fontWeight:'600',marginLeft:'3vw',fontSize:'3vh'}}>Series</div>
                    </div>}
                    <div className='leftContainerStyle'>
                        {
                            imageList.map((image:string, index:number)=>{
                            //console.log(imageList);
                            //console.log(<ListSeries activeSeries={activeIndex} setActiveSeries={setActiveIndex} index={index} seriesNumber={index + 1} imageIds={[image]} totalImages={1} key={index} />)
                                return(
                                        <div>
                                        <ListSeries fromReporting={fromReporting} activeSeries={activeIndex} setActiveSeries={setActiveIndex} index={index} seriesNumber={index + 1} imageIds={[image]} totalImages={1} key={index} prefetch={prefetch}/>
                                        </div>
                                    )
                            })
                        }
                    </div>
                </Col>
                <Col xs={9}>
                            <XrayViewPort fromReporting={fromReporting} noOfImages={imageList.length} handleChangeImageClick={handleChangeImageClick} fullView={fullView} images={[imageList[activeIndex]]} activeTool={activeTool} actionTool={actionTool} setActionTool={setActionTool} viewportSize={fromReporting  ? '78vh' : viewportSize}/> 
                </Col> 
            </Row>
            </div>
        </DndProvider>

       
    </div>
     // <div id='#container'>
        // <Container fluid>
        //     <Row style={{ backgroundColor: '#000' }}>
        //             {fullView ? null : <Col xs={ 3}> <VerticalCarousel image={imageList[activeIndex]} handleChangeImageClick={handleChangeImageClick} activeTool={activeTool} setActiveTool={setActiveTool} setActionTool={setActionTool} imageList={imageList} activeIndex={activeIndex}/></Col>}
        //         <Col xs={fullView ? 12 : 9}><XrayViewPort noOfImages={imageList.length} handleChangeImageClick={handleChangeImageClick} fullView={fullView} images={[imageList[activeIndex]]} activeTool={activeTool} actionTool={actionTool} setActionTool={setActionTool}/></Col> 
        //     </Row>
        // </Container>
        // // </div> 
    )
}

export default XrayViews
