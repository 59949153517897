import React, { CSSProperties, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDrop } from 'react-dnd'
import CornerstoneViewport from "react-cornerstone-viewport";
import { Slider } from '@mui/material';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from "cornerstone-tools";
import { DcmViewerTools } from '../../constants/Radiology';
import initCornerstone from '../../initCornerstone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faPauseCircle } from "@fortawesome/free-solid-svg-icons";
import { HoritontalTools } from './HoritontalTools';
import { DisplaySeries } from './DisplaySeries';
import canvasToImage from "canvas-to-image";

const ItemTypes = {
    BOX: 'box',
}

const style: CSSProperties = {
    height: '50vh',
    width: '95%',
    overflow: 'hidden',
    // padding: '20px',
    // background: 'red',
    // marginRight: '1.5rem',
    // marginBottom: '1.5rem',
    color: 'white',
    // padding: '1rem',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    // float: 'left',
    // border: '1px solid #84AED4'
}

export const Dicomportlet: any = (props: { imageIds: string[], series: any, activeSeries: any, setActiveSeries: any }) => {
    const [loading, setLoading] = React.useState(true);
    const [cornerStonestate, setCornerStoneState] = React.useState(null as any);
    const [frameRate, setFrameRate] = React.useState(10);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [state, setState] = React.useState({} as any);
    const [activeTool, setActiveTool] = React.useState('Wwwc');
    const [actionTool, setActionTool] = React.useState('');
    let backgroundColor = '#222';
    function frameRateValue(value: number, index: number): string {
        return `Frame Rate ${value}`;
    }


    useEffect(() => {
        //console.log("Updatedviewport:",actionTool)
        if (cornerStonestate?.cornerstoneElement && actionTool) {
            const { viewport, cornerstoneElement } = cornerStonestate;
            const updatedViewport = Object.assign({}, viewport, {
                [actionTool]: !viewport[actionTool],
            });
            setCornerStoneState({ cornerstoneElement, viewport: updatedViewport });

            cornerstone.setViewport(
                cornerstoneElement,
                updatedViewport
            );

            setActionTool('');
        }
    }, [actionTool]);
    
    const downloadImage = (class_name: string, type:string, fileName:string)=>{
        const element = document.getElementsByClassName(
            class_name
          )[
            document.getElementsByClassName(class_name)
              .length - 1
          ];
        //console.log("It's here",type, element)
          if (type == 'png'){
            canvasToImage(element.children[0], {
                name: fileName,
                type: "png",
                quality: 1,
              });
        }
    }
    
    const onEnabled = (elementEnabledEvt: any) => {

        const cornerstoneElement = elementEnabledEvt.detail.element;
        const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool

        //define the stack
        const stack = {
            currentImageIdIndex: 0,
            imageIds: props.imageIds
        }
        cornerstoneTools.addTool(StackScrollMouseWheelTool)
        cornerstoneTools.addStackStateManager(cornerstoneElement, ['stack']);
        cornerstoneTools.addToolState(cornerstoneElement, 'stack', stack)
        setState(Object.assign({}, state, { cornerstoneElement }));
        let viewport = "";
        cornerstoneElement.addEventListener(
            "cornerstonenewimage",
            (imageRenderedEvent: any) => {
                viewport = imageRenderedEvent.detail.viewport;
                setCornerStoneState({ cornerstoneElement, viewport });
                cornerstone.setViewport(cornerstoneElement, viewport);
            }
        );
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column' }}>
            <div style={{ background: '#000', display: 'flex', flexDirection: 'row', border: '1px solid #84AED4', padding: '0px', justifyContent: 'flex-start', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', width: '20%' }}>
                    {isPlaying ? <div>
                        <FontAwesomeIcon icon={faPauseCircle} style={{ fontSize: '30px', color: '#FFF', cursor: 'pointer' }} onClick={() => setIsPlaying(false)} /></div> : <div> <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: '30px', color: '#FFF', cursor: 'pointer' }} onClick={() => setIsPlaying(true)} /></div>}

                    {isPlaying ? <div style={{ width: '100%' }}> <Slider

                        color='primary'
                        aria-label="FrameRate"
                        defaultValue={frameRate}
                        getAriaValueText={frameRateValue}
                        valueLabelDisplay="auto"
                        onChange={(event: Event, value: any, actuveThumb: number) => {
                            setFrameRate(value);
                        }}
                        step={5}
                        marks
                        min={1}
                        max={60}
                    /></div> : null}

                </div>
                <div style={{ display: 'flex',  maxWidth: '80%', gap: '15px' }}>
                    {props.series.map((ser: any, index: number) => {
                        return <div key={index}
                            onClick={() => props.setActiveSeries(index)}
                            style={{ display: 'flex', cursor: 'pointer', alignContent: 'center', justifyContent: 'space-around', alignItems: 'center', flexDirection: "column", border: '1px solid #FFF' }}>
                            <div>
                                <CornerstoneViewport
                                    isOverlayVisible={false}
                                    tools={DcmViewerTools}
                                    activeTool={activeTool}
                                    frameRate={frameRate}
                                    isPlaying={false}
                                    imageIds={[ser[0].url]}
                                    style={{ width: '80px', height: '40px' }}
                                    onElementEnabled={onEnabled}
                                />
                            </div>
                            <div style={props.activeSeries === index ? { color: '#84AED4', fontWeight: 'bold', fontSize: '16px'}  : { color: '#FFF' }}>
                                <DisplaySeries imageIds={ser.map((image: any) => image.url)} seriesName={'Ser '+(index + 1)} /> 
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <div style={{ height: '80vh', width: '100vh' }}>
                {
                    isPlaying ? <CornerstoneViewport
                        isOverlayVisible={true}
                        tools={DcmViewerTools}
                        activeTool={activeTool}
                        frameRate={frameRate}
                        isPlaying={isPlaying}
                        imageIds={props.imageIds}
                        style={{ width: '100vw', height: '75vh' }}
                        onElementEnabled={onEnabled}
                    /> : <CornerstoneViewport
                        isOverlayVisible={true}
                        tools={DcmViewerTools}
                        activeTool={activeTool}
                        isPlaying={false}
                        imageIds={props.imageIds}
                        style={{ width: '100vw', height: '75vh' }}
                        onElementEnabled={onEnabled}
                    />}



            </div>

            <div style={{padding: '10px', display: 'flex', color: '#FFF', background: '#000', border: '5px solid #FFF', width: '100vw' }}>
                <div style={{ backgroundColor: 'black', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center' }}>

                    <div style={{ width: '100%' }}>
                        <HoritontalTools
                            layout={1}
                            setLayout={(layout: number) => {
                                // setLayout(layout);
                                // setTimeout(() => {
                                //     setState(Object.assign({}, state, { activeTool: 'StackScrollMouseWheel' }));
                                // }, 10);
                            }}
                            activeTool={activeTool}
                            selectActiveTool={(activeTool: string) => {
                                setActiveTool(activeTool);
                            }}
                            viewportAction={(actionProperty: string, imgType?: string) => {
                                setActionTool(actionProperty);
                                // console.log("Action Property", actionProperty);
                                
                            }}
                            downloadImage={downloadImage}
                        />
                    </div>

                </div>

            </div>
        </div >
    )
}

export default Dicomportlet
