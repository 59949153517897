import React, { CSSProperties, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useDrop } from 'react-dnd'
import CornerstoneViewport from "react-cornerstone-viewport";
import {  Slider } from '@mui/material';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from "cornerstone-tools";
import { DcmViewerTools } from '../../constants/Radiology';
import initCornerstone from '../../initCornerstone';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faPauseCircle } from "@fortawesome/free-solid-svg-icons";
import { HoritontalTools } from './HoritontalTools';
import canvasToImage from "canvas-to-image";

const ItemTypes = {
    BOX: 'box',
}

const style: CSSProperties = {
    height: '100%',
    width: '98%',
    overflow:'hidden',
    // marginRight: '1.5rem',
    // marginBottom: '1.5rem',
    color: 'white',
    // padding: '1rem',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    // float: 'left',
    // border: '1px solid #84AED4'
}

export const RenderCathlab: any = (props: { imageIds: string[] }) => {
    const [loading, setLoading] = React.useState(true);
    const [cornerStonestate, setCornerStoneState] = React.useState(null as any);
    const [frameRate, setFrameRate] = React.useState(10);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [ state, setState ] = React.useState({} as any);
    const [activeTool, setActiveTool] = React.useState('Wwwc');
    const [actionTool, setActionTool] = React.useState('');
    const [_, drop] = useDrop(() => ({
        accept: ItemTypes.BOX,
        drop: () => ({ name: 'Dustbin' }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }))

    // const isActive = canDrop && isOver
    let backgroundColor = '#222';
    function frameRateValue(value: number, index: number): string {
        return `Frame Rate ${value}`;
    }

    useEffect(() => {
        initCornerstone();
        return () => {

        }
       
    }, []);

    useEffect(() => {
        if (cornerStonestate?.cornerstoneElement && actionTool) {
            const { viewport, cornerstoneElement } = cornerStonestate;
            const updatedViewport = Object.assign({}, viewport, {
                [actionTool]: !viewport[actionTool],
            });

            setCornerStoneState({ cornerstoneElement, viewport: updatedViewport });

            cornerstone.setViewport(
                cornerstoneElement,
                updatedViewport
            );

            setActionTool('');
        }
    }, [actionTool]);
    const downloadImage = (class_name: string, type:string, fileName:string)=>{
        const element = document.getElementsByClassName(
            class_name
          )[
            document.getElementsByClassName(class_name)
              .length - 1
          ];
          if (type == 'png'){
            canvasToImage(element.children[0], {
                name: fileName,
                type: "png",
                quality: 1,
              });
        }
    }
    const onEnabled = (elementEnabledEvt: any) => {
     
            const cornerstoneElement = elementEnabledEvt.detail.element;
            const StackScrollMouseWheelTool = cornerstoneTools.StackScrollMouseWheelTool

            //define the stack
            const stack = {
                currentImageIdIndex: 0,
                imageIds: props.imageIds
            }
            cornerstoneTools.addTool(StackScrollMouseWheelTool)
            cornerstoneTools.addStackStateManager(cornerstoneElement, ['stack']);
            cornerstoneTools.addToolState(cornerstoneElement, 'stack', stack)
            setState(Object.assign({}, state, { cornerstoneElement }));
            let viewport = "";
            cornerstoneElement.addEventListener(
                "cornerstonenewimage",
                (imageRenderedEvent: any) => {
                    viewport = imageRenderedEvent.detail.viewport;
                    setCornerStoneState({ cornerstoneElement, viewport });
                    cornerstone.setViewport(cornerstoneElement, viewport);
                }
            );
    }


    return (
        <div ref={drop} role={'Dustbin'} style={{ ...style, backgroundColor }}>
            <Row>
                <Col xs={12}>
                    <div style={{ backgroundColor: 'black', width: '99.5%', height: '7vh', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignContent: 'center', marginBottom: '2px', borderBottom: '5px solid #84AED4' }}>
                        <div style={{ width: '10%', borderRight: '2px solid #84AED4', borderLeft: '2px solid #84AED4', paddingLeft: '20px', paddingRight: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {isPlaying ? <FontAwesomeIcon icon={faPauseCircle} style={{ fontSize: '30px', color: '#84AED4', cursor: 'pointer' }} onClick={() => setIsPlaying(false)} /> : <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: '30px', color: '#84AED4', cursor: 'pointer' }} onClick={() => setIsPlaying(true)} />}
                        </div>
                        <div style={{ width: '15%', borderRight: '2px solid #84AED4', paddingLeft: '20px', paddingRight: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <h6 style={{ marginBottom: '0px', color: '#84AED4' }}> Frame Rate </h6>
                            <Slider
                                aria-label="FrameRate"
                                defaultValue={frameRate}
                                getAriaValueText={frameRateValue}
                                valueLabelDisplay="auto"
                                onChange={(event: Event, value: any, actuveThumb: number) => {
                                    setFrameRate(value);
                                }}
                                step={5}
                                marks
                                min={1}
                                max={60}
                            />
                        </div>
                        <div style={{width: '75%'}}>
                            <HoritontalTools
                                layout={1}
                                setLayout={(layout: number) => {
                                    // setLayout(layout);
                                    // setTimeout(() => {
                                    //     setState(Object.assign({}, state, { activeTool: 'StackScrollMouseWheel' }));
                                    // }, 10);
                                }}
                                activeTool={activeTool}
                                selectActiveTool={(activeTool: string) => {
                                    setActiveTool(activeTool);
                                }}
                                viewportAction={(actionProperty: string, imgType?: string) => {
                                    setActionTool(actionProperty);
                                }}
                                downloadImage={downloadImage}
                            />
                        </div>

                    </div>
                </Col>            
                <Col xs={12}>

                    {
                        isPlaying ? <CornerstoneViewport
                        tools={DcmViewerTools}
                        activeTool={activeTool}
                        frameRate={frameRate}
                        isPlaying={isPlaying}
                        imageIds={props.imageIds}
                        style={{ width: '99.5%', height: loading ? '93vh' : '93vh', flex: '1' }}
                        onElementEnabled={onEnabled}
                        /> : <CornerstoneViewport
                            tools={DcmViewerTools}
                            activeTool={activeTool}
                            isPlaying={false}
                            imageIds={props.imageIds}
                            style={{ width: '99%', height: loading ? '93vh' : '93vh', flex: '1' }}
                                onElementEnabled={onEnabled}
                        />}
                </Col>
               

            </Row>
        </div>
    )
}

export default RenderCathlab
