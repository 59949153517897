import React, { useState, useEffect } from "react";
import CornerstoneViewport from "react-cornerstone-viewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp,faBars} from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ToolsList } from "./Radiology/ToolsList";
import canvasToImage from "canvas-to-image";
import { isAndroid, isMobile } from "../constants/Radiology";


function TabPanel(props: any) {
    const { children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                   {children}
                 </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const VerticalCarousel = (props: any) => {
    const { handleChangeImageClick, image, activeTool, setActiveTool, setActionTool,imageList,activeIndex} = props;
    const [loading, setLoading] = useState(true);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    useEffect(() => {
        setTimeout(() => setLoading(false), 100)
    }, []);

    if (loading) {
        return null;
    }

    const downloadImage = (class_name: string, type:string, fileName:string)=>{
        const element = document.getElementsByClassName(
            class_name
          )[
            document.getElementsByClassName(class_name)
              .length - 1
          ];
        //console.log("It's here",type, element)
          if (type == 'png'){
            canvasToImage(element.children[0], {
                name: fileName,
                type: "png",
                quality: 1,
              });
        }
    }


    return (
        <div className="curosal-container" style={{marginTop:-10}} >
            <section className="outer-curosal-container">
                <Box sx={{ width: '100%',borderRight:1,borderRightColor:'#605c5c' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider',paddingBottom:'-10%', }}>
                        {isMobile() ?
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{height:'60px', display: 'flex',flexDirection: 'column',marginBottom:-5}}>
                            <Tab label={<div>Series</div>}style={{color: value === 0 ? '#2680EB' : '#FFF', flexDirection: 'row',width:'50%',marginTop:10}} />
                            <Tab label="Tools" style={{ color: value === 1 ? '#2680EB' : '#FFF', flexDirection: 'row',width:'49.5%',marginLeft:-15,marginTop:10}} />
                        </Tabs>
                        :<Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{height:'60px', display: 'flex',flexDirection: 'column'}}>
                            <Tab icon={<FontAwesomeIcon icon={faBars}  style={{margin:10}}/>} label={<div>Series</div>}style={{color: value === 0 ? '#2680EB' : '#FFF', flexDirection: 'row',width:'50%'}} />
                            <Tab icon={<FontAwesomeIcon icon={faBars}  style={{margin:10}}/>} label="Tools" style={{ color: value === 1 ? '#2680EB' : '#FFF', flexDirection: 'row',width:'49.5%',marginLeft:-5}} />
                        </Tabs>}
                    </Box>
                    <TabPanel value={value} index={0}>
                        <div className="carousel-wrapper" style={isMobile() ? {height:'75vh'} : {}}>
                            <div
                                className="prev"
                                style={{ height: '30px', width: '100%', textAlign: 'center', color: '#FFF', backgroundColor: '#081a2f',borderRadius: '8px 8px 0px 0px' }}
                                onClick={() => handleChangeImageClick("prev")}
                            >

                                <FontAwesomeIcon icon={faAngleUp} size="2x" />

                            </div>

                            <div  style={{ width: '100%', height: isMobile() ? '50vh ':'60vh',paddingTop:'20%',display:'flex',alignItems: 'center',justifyContent:'center', flexDirection:'column'}}>
                                <div style={{border:'1px solid #fff',padding:'2px',borderRadius:'8px',zIndex:1,alignItems: 'center',justifyContent:'center',flexWrap:'wrap'}}>
                                    <CornerstoneViewport
                                        isOverlayVisible={false}
                                        style={isAndroid() ? {maxWidth: '40vw', maxHeight: '40vh'} : {maxWidth: '20vw', maxHeight: '22vh'}}
                                        imageIds={[image]}
                                        onElementEnabled={(elementEnabledEvt: any) => {}}
                                    />
                                </div>

                                <div style={{
                                    textAlign:'center',
                                    width: '100%',
                                    color:'#fff',
                                    padding:'1%',
                                    fontSize:'18px',
                                    marginTop:'5%'}}>{activeIndex+1}/{imageList.length}</div>
                            </div>

                            <div

                                className="prev"
                                style={{ height: '30px', width: '100%', textAlign: 'center', color: '#FFF', backgroundColor: '#081a2f',borderRadius: '0px 0px 8px 8px',marginTop:'15%' }}
                                onClick={() => handleChangeImageClick("next")}
                            >
                                <FontAwesomeIcon icon={faAngleDown} size="2x"/>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div style={{height: isMobile() ? '75vh' : '90vh',overflowY: 'auto'}}>
                        <ToolsList
                            layout={1}
                            setLayout={(layout: number) => {
                                // setLayout(layout);
                                // setTimeout(() => {
                                //     setState(Object.assign({}, state, { activeTool: 'StackScrollMouseWheel' }));
                                // }, 10);
                            }}
                            activeTool={activeTool}
                            selectActiveTool={(activeTool: string) => {
                                setActiveTool(activeTool);
                            }}
                            viewportAction={(actionProperty: string, imgType?: string) => {
                                setActionTool(actionProperty);
                                // console.log(updatedViewport);

                            }}
                            downloadImage={downloadImage}
                        />
                        </div>
                    </TabPanel>
                    
                </Box>
               
            </section>
        </div>
    );
};

export default VerticalCarousel;