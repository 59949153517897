import React, { CSSProperties, useEffect } from 'react'
import { useDrag } from 'react-dnd'
import CornerstoneViewport from "react-cornerstone-viewport";
import cornerstone from 'cornerstone-core';

const ItemTypes = {
    BOX: 'box',
}

let style: CSSProperties = {
    // border: '1px dashed gray',
    backgroundColor: 'transparent',
    // padding: '10px',
    // marginRight: '1.5rem',
    // marginBottom: '1.5rem',
    cursor: 'move',
    width: '70%',
    // float: 'left',
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex',
    textAlign: 'center',
    border: '1px solid #84AED4',
    margin: '2vw',
    flexWrap:'wrap',
    marginBottom: '10px'


}

export interface BoxProps {
    name: string
}

interface DropResult {
    name: string
}

const ListSeries = (props: any) => {
    const { name, seriesNumber, totalImages, activeSeries, index, setActiveSeries, donePrefetching,prefetch} = props;
    const [loading, setLoading] = React.useState(true);
    const [fetchCount, setFetchingImage] = React.useState(0);
    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.BOX,
        item: { name },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult<DropResult>()
            if (item && dropResult) {
                console.log(dropResult);
                // alert(`You dropped ${item.name} into ${dropResult.name}!`)
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }))

    const opacity = isDragging ? 0.4 : 1



    style.border = activeSeries === index ? '5px solid #84AED4' : '2px solid #2680EB';


    useEffect(() => {
       let count = 0;
       Promise.all(props.imageIds.map(async (imageUrl: any) => {
           const image = await cornerstone.loadAndCacheImage(imageUrl);
            count += 1;
            setFetchingImage(count);
            return image;
        })).then(() => {
            setLoading(false);
        }).catch((e) => {
            console.log(e);
        });
        // return () =>  {cornerstone.purgeCache();}
    }, [])


    return (
        // <div style={ContainerStyle}>

        <>
            <div
                ref={drag}
                role="Box"
                style={{ ...style, opacity }}
                data-testid={`box-${name}`}
                data-name={'test'}
                onClick={() => setActiveSeries(index)}
            >

                <div style={{ width: '80%',marginTop: '20px', padding: '5px', height: '150px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <div style={{ marginTop: '10px' }}> <CornerstoneViewport
                        key={0}
                        isPlaying={false}
                        style={{ width: '100%', height: '120px' }}
                        imageIdIndex={0}
                        isOverlayVisible={false}
                        activeTool={''}
                        frameRate={22}
                        // tools={DcmViewerTools}
                        imageIds={[props.imageIds[0]]}
                        // style={{ minWidth: '100%', height: '100vh', flex: '1', color: '#FFF' }}
                        onElementEnabled={(elementEnabledEvt: any) => {
                        }}
                    />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', color: '#84AED4', fontSize: '12px',paddingBottom:'20px', }}> <div>Ser:{seriesNumber}</div> <div style={ totalImages === fetchCount ? {fontSize:'1.5vh',color: 'green', fontWeight: 'bold'} : {}}> {totalImages} Images</div></div>

                </div>
            </div>
            {loading ? prefetch ? <div style={{ textAlign: 'center', color: '#84AED4' }}>Prefetching {fetchCount} / {totalImages} Frames</div> : null : null}
        </>

    )
}

export default ListSeries;