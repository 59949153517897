/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Col, Container, Nav, Navbar, NavDropdown, Row, Spinner } from 'react-bootstrap'
import XrayViewPort from '../components/XrayViewPort';
import VerticalCarousel from '../components/VerticalCurosal';
import "../styles/Left-carousel.css";

import cornerstone from "cornerstone-core";
import initCornerstone from '../initCornerstone';
import { SpinnerLoader } from '../components/SpinnerLoader';
import axios from 'axios';
import { useParams } from 'react-router';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import ListSeries from '../components/Radiology/ListSeries';
import { LinearProgress } from '@mui/material';
import { XrayImageLoader } from '../components/Radiology/XrayImageLoader';
import { HoritontalTools } from '../components/Radiology/HoritontalTools';
import canvasToImage from "canvas-to-image";
import { isAndroid } from '../constants/Radiology';
const imgSrc1 = 'wadouri:https://dkb1p83dn9zja.cloudfront.net/8ada5da1-7808-41a2-9632-d647ba48619a/1.2.392.200036.9107.307.30301.113030122011901629/1.2.392.200036.9107.307.30301.20220119.93940.1007762.dcm';
const imgSrc2 = 'wadouri:https://dkb1p83dn9zja.cloudfront.net/8ada5da1-7808-41a2-9632-d647ba48619a/1.2.392.200036.9107.307.30301.113030122011901629/1.2.392.200036.9107.307.30301.20220119.93941.1007763.dcm';
const imageListSrc = [imgSrc1, imgSrc2];

function detectMob() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some(toMatchItem => {
        return navigator.userAgent.match(toMatchItem);
    });
}


const Sharexray = (props: any) => {
    const fullView = window.location.href.indexOf('showxray-fullview') > -1;
    const [activeIndex, setActiveIndex] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [imageList, setImageList] = useState([] as any);
    const nonCompressedUrl = 'https://d1oizyeanpa61n.cloudfront.net/';
    const newUrl = 'https://d3u5v8av7fvaaf.cloudfront.net/';
    const compressedUrl = 'https://d1cz83de831o86.cloudfront.net/';
    // 'https://dicomuploadsv2-compressed.s3.ap-south-1.amazonaws.com/';
    //'https://d1cz83de831o86.cloudfront.net/';

    const [activeTool, setActiveTool] = useState('Wwwc');
    // eg. vflip
    const locationObj = useParams();
    const { centerid, instanceid }: any = locationObj;
    const [actionTool, setActionTool] = React.useState('');
    const images: string[] = [];
    const [activeSeries, setActiveSeries] = useState(0);
    const [prefetch, SetPrefetch] = useState(false);
    const [viewportSize] = useState('85vh');
    const fromReporting = window.location.href?.indexOf('?report=true') > -1;


    const downloadImage = (class_name: string, type: string, fileName: string) => {
        const element = document.getElementsByClassName(
            class_name
        )[
            document.getElementsByClassName(class_name)
                .length - 1
        ];
        //console.log("It's here",type, element)
        if (type == 'png') {
            canvasToImage(element.children[0], {
                name: fileName,
                type: "png",
                quality: 1,
            });
        }
    }

    const LeftContainerStyle = {
        height: '85vh',
        width: '98%',
        background: '#000',
        padding: '10px',
        overflow: 'scroll',
        paddingBottom: '50px',
    }

    useEffect(() => {
        SetPrefetch(false)
        initCornerstone();
        try {
            (async () => {
                const options = {
                    onUploadProgress: (progressEvent: any) => {
                        const { loaded, total } = progressEvent;
                        const percent = Math.floor((loaded * 100) / total);

                        if (percent <= 100) {
                            setPercentage(percent);
                        }
                    }
                };
                let studyHash = instanceid?.replaceAll('###', '/');
                studyHash = instanceid?.replaceAll('---', '/');
                studyHash = studyHash.replaceAll('%20', ' ');
                const centerInfoResponse = await axios.get('https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod/medicalCenter/'+centerid);
                const obj = await axios.post('https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod/radiology-studies/by-hash/' + centerid, {
                    studyHash
                });
                const centerInfo = centerInfoResponse.data[0];

                const data = obj.data;
                //console.log(data);
                const listOfImages = data.filteredList[0].urls;

                let imageList = listOfImages.map((image: any) => {
                    return image.url;
                })
                // "compressionStartDateTime": "2022-06-30T11:30:29.119Z",
                // "defaultReferral": "DR.J.ARUL DURAI ARASU.,MS.,DNB.D.ORTHO.",
                //  "useCompressionForMobileXray": true,
               
                if (centerInfo?.useCompressionForMobileXray && detectMob() && !isAndroid()) {
                    if (centerInfo?.compressionStartDateTime && Date.parse((data.filteredList[0].uploadDate)) > Date.parse(centerInfo?.compressionStartDateTime)) {
                         imageList = listOfImages.map((image: any) => {
                            return image.url.replace(nonCompressedUrl, compressedUrl);
                        });
                    }
                }

                setImageList(imageList);
                setLoading(false);
            }
            )()
        }
        catch (e) {
            console.log(e);
        }
    }, [])

    cornerstone.events.addEventListener('cornerstoneimageloadprogress', function (event: any) {
        const eventData = event.detail;
        console.log("eventdata", eventData.percentComplete);
        setPercentage(eventData.percentComplete);
    });

    const handleChangeImageClick = (direction: any) => {
        setActiveIndex((prevIndex: any) => {
            if (direction === "next") {
                if (prevIndex + 1 > imageList.length - 1) {
                    return 0;
                }
                return prevIndex + 1;
            }

            if (prevIndex - 1 < 0) {
                return imageList.length - 1;
            }

            return prevIndex - 1;
        });
    };

    if (loading) {
        return <div style={{ width: '100vw', height: '100vh', backgroundColor: '#000' }}>  <div style={{ padding: '20px' }}> <SpinnerLoader message={'Loading x-ray files, please wait ...'} />; </div></div>;
    }

    return (
        <div id='#container'>
        <Container fluid>
            <Row style={{ backgroundColor: '#000' }}>
                    {fullView ? null : <Col xs={ 3}> <VerticalCarousel image={imageList[activeIndex]} handleChangeImageClick={handleChangeImageClick} activeTool={activeTool} setActiveTool={setActiveTool} setActionTool={setActionTool} imageList={imageList} activeIndex={activeIndex}/></Col>}
                <Col xs={fullView ? 12 : 9}><XrayViewPort noOfImages={imageList.length} handleChangeImageClick={handleChangeImageClick} fullView={fullView} images={[imageList[activeIndex]]} activeTool={activeTool} actionTool={actionTool} setActionTool={setActionTool}/></Col> 
            </Row>
        </Container>
        </div> 
    )
}

export default Sharexray
