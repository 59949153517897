import { useEffect, useState } from 'react'
import { useMobileOrientation } from 'react-device-detect';
import { Col, Container, Row, Spinner } from 'react-bootstrap'
import XrayViewPort from '../components/XrayViewPort';
import VerticalCarousel from '../components/VerticalCurosal';
import "../styles/Left-carousel.css";
import axios from 'axios';
import { useParams } from 'react-router';
import cornerstone from "cornerstone-core";
import initCornerstone from '../initCornerstone';
import { SpinnerLoader } from '../components/SpinnerLoader';
import { ToolsList } from '../components/Radiology/ToolsList';
import canvasToImage from "canvas-to-image";
import { ToolsListMobile } from '../components/Radiology/ToolsListMobile';
import { isMobile} from '../constants/Radiology';
import VerticalCarouselMobile from '../components/VerticalCurosalMobile';

const imgSrc1 = 'wadouri:https://dkb1p83dn9zja.cloudfront.net/8ada5da1-7808-41a2-9632-d647ba48619a/1.2.392.200036.9107.307.30301.113030122011901629/1.2.392.200036.9107.307.30301.20220119.93940.1007762.dcm';
const imgSrc2 = 'wadouri:https://dkb1p83dn9zja.cloudfront.net/8ada5da1-7808-41a2-9632-d647ba48619a/1.2.392.200036.9107.307.30301.113030122011901629/1.2.392.200036.9107.307.30301.20220119.93941.1007763.dcm';
const imageListSrc = [imgSrc1, imgSrc2];


const Showxray = (props: any) => {
    const { isPortrait } = useMobileOrientation()
    const fullView = window.location.href.indexOf('showxray-fullview')>-1;
    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [imageList, setImageList] = useState([] as any);
    const [activeTool, setActiveTool] = useState('Wwwc');
    const [percentage, setPercentage] = useState(0);
    const locationObj = useParams();
    const images: string[] = [];
    const {centerid, instanceid}: any = locationObj;
    // eg. vflip
    const [actionTool, setActionTool] = useState('');

    useEffect(()=>{
        initCornerstone();
        try {
            const listOfImages = [...(window as any).dicomUrls ? ((window as any).dicomUrls) : imageListSrc];
            setImageList([...listOfImages]);
            setLoading(false);
            /*(async ()=> {
            const options = {
                onUploadProgress:  (progressEvent: any) => {
                    const { loaded, total } = progressEvent;
                    const percent = Math.floor((loaded * 100) / total);
            
                    if (percent <= 100) {
                    setPercentage(percent);
                    }
                }
                };
            let studyHash = instanceid?.replaceAll('###', '/');
            studyHash = studyHash.replaceAll('%20', ' ');
            const obj = await axios.post('https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod/radiology-studies/by-hash/' + centerid,{
                studyHash
            }) ; 
            const data = obj.data;
            //console.log(data);
            const listOfImages = data.filteredList[0].urls;
            
            const compressedImagesList = listOfImages.map((image: any)=>{
                return image.compressedUrl;
            })
            setImageList([...compressedImagesList]);
            setLoading(false);*/
            // Promise.all(listOfImages.map(async (imageUrl: any) => {
            //     const image = await cornerstone.loadAndCacheImage(imageUrl);
            //     return image;
            // })).then(() => {
            //    // setImageList([...images]);
            //     //console.log(images);
            //     setLoading(false);
            // }).catch((e) => {
            //     console.log(e);
            // });
        } catch(e){
            console.log(e);
        }
    }, [])
    const handleChangeImageClick = (direction: any) => {
        setActiveIndex((prevIndex: any) => {
            if (direction === "next") {
                if (prevIndex + 1 > imageList.length - 1) {
                    return 0;
                }
                return prevIndex + 1;
            }

            if (prevIndex - 1 < 0) {
                return imageList.length - 1;
            }

            return prevIndex - 1;
        });
    };

    const downloadImage = (class_name: string, type:string, fileName:string)=>{
        const element = document.getElementsByClassName(
            class_name
          )[
            document.getElementsByClassName(class_name)
              .length - 1
          ];
        //console.log("It's here",type, element)
          if (type == 'png'){
            canvasToImage(element.children[0], {
                name: fileName,
                type: "png",
                quality: 1,
              });
        }
    }

    if (loading) {
        return <div style={{ width: '100vw', height: '100vh', backgroundColor: '#000' }}>  <div style={{ padding: '20px' }}> <SpinnerLoader message={'Loading x-ray files, please wait ...'} />; </div></div>;
    }

    return (
        // <div id='#container'>
        <Container fluid style={{ backgroundColor: '#000',overflow:'none'}} >
            <Row style={{ backgroundColor: '#000',height:'85vh' }} >
                {fullView ? null :
                     <Col xs={3}>{isMobile() && isPortrait ?<VerticalCarouselMobile image={imageList[activeIndex]} handleChangeImageClick={handleChangeImageClick} activeTool={activeTool} setActiveTool={setActiveTool} setActionTool={setActionTool} imageList={imageList} activeIndex={activeIndex}/>
                                  : <VerticalCarousel image={imageList[activeIndex]} handleChangeImageClick={handleChangeImageClick} activeTool={activeTool} setActiveTool={setActiveTool} setActionTool={setActionTool} imageList={imageList} activeIndex={activeIndex}/>}
                    </Col>}
                     <Col xs={fullView ? 12 : 9}>
                        {isMobile() && isPortrait ? <XrayViewPort noOfImages={imageList.length} handleChangeImageClick={handleChangeImageClick} fullView={fullView} images={[imageList[activeIndex]]} activeTool={activeTool} actionTool={actionTool} setActionTool={setActionTool} viewportSize={'85vh'} />
                         : <XrayViewPort noOfImages={imageList.length} handleChangeImageClick={handleChangeImageClick} fullView={fullView} images={[imageList[activeIndex]]} activeTool={activeTool} actionTool={actionTool} setActionTool={setActionTool} />}
                    </Col> 
            </Row>
            {isMobile() && isPortrait ? <Row style={{ backgroundColor: '#000',}}> 
                <div style={{ backgroundColor: 'black', width: '100%', display: 'flex',borderTop: '1px solid #605c5c',borderBottom: '1px solid #605c5c',marginBottom:'-30px'}}>
                    <div style={{ width: '100%' }}>
                        <ToolsListMobile
                            layout={1}
                            setLayout={(layout: number) => {
                                // setLayout(layout);
                                // setTimeout(() => {
                                //     setState(Object.assign({}, state, { activeTool: 'StackScrollMouseWheel' }));
                                // }, 10);
                            }}
                            activeTool={activeTool}
                            selectActiveTool={(activeTool: string) => {
                                setActiveTool(activeTool);
                            }}
                            viewportAction={(actionProperty: string, imgType?: string) => {
                                setActionTool(actionProperty);
                                // console.log("Action Property", actionProperty);
                                
                            }}
                            downloadImage={downloadImage}
                        />
                    </div>

                    </div>
                    </Row> : null} 
        </Container>
        // </div>
    )
}

export default Showxray
