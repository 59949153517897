import React, { useState, useEffect } from "react";
import CornerstoneViewport from "react-cornerstone-viewport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp,faBars} from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ToolsList } from "./Radiology/ToolsList";
import canvasToImage from "canvas-to-image";
import { isAndroid, isMobile,} from "../constants/Radiology";


function TabPanel(props: any) {
    const { children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                   {children}
                 </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const VerticalCarouselMobile = (props: any) => {
    const { handleChangeImageClick, image, activeTool, setActiveTool, setActionTool,imageList,activeIndex} = props;
    const [loading, setLoading] = useState(true);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: any, newValue: any) => {
        setValue(newValue);
    };

    useEffect(() => {
        setTimeout(() => setLoading(false), 100)
    }, []);

    if (loading) {
        return null;
    }
    
    return (
        <div className="curosal-container" style={{marginTop:-10,}} >
            <section className="outer-curosal-container">
                <Box sx={{ width: '100%',height:'100%',borderRight:1,borderRightColor:'#605c5c', }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider',paddingBottom:'-10%', }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{height:'60px', display: 'flex',flexDirection: 'column'}}>
                            <Tab label={<div>Series</div>} style={{ color: value === 0 ? '#2680EB' : '#FFF',marginTop:10,width:'100%'}} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <div className="carousel-wrapper" style={{height:'70vh'}}>
                            <div
                                className="prev"
                                style={{ height: '30px', width: '100%', textAlign: 'center', color: '#FFF', backgroundColor: '#081a2f',borderRadius: '8px 8px 0px 0px' }}
                                onClick={() => handleChangeImageClick("prev")}
                            >

                                <FontAwesomeIcon icon={faAngleUp} size="2x" />

                            </div>

                            <div  style={{ width: '100%', height: '100%',display:'flex',alignItems: 'center',justifyContent:'center', flexDirection:'column'}}>
                                <div style={{border:'1px solid #fff',padding:'2px',borderRadius:'8px',zIndex:1,alignItems: 'center',justifyContent:'center',flexWrap:'wrap'}}>
                                    <CornerstoneViewport
                                        isOverlayVisible={false}
                                        style={ isAndroid() ? { maxWidth: '30vw', maxHeight: '30vh'  }  : { maxWidth: '18vw', maxHeight: '12vh'  }}
                                        imageIds={[image]}
                                        onElementEnabled={(elementEnabledEvt: any) => {}}
                                    />
                                </div>

                                <div style={{
                                    textAlign:'center',
                                    width: '100%',
                                    color:'#fff',
                                    padding:'1%',
                                    fontSize:'18px',
                                    }}>{activeIndex+1}/{imageList.length}</div>
                            </div>

                            <div

                                className="prev"
                                style={{ height: '30px', width: '100%', textAlign: 'center', color: '#FFF', backgroundColor: '#081a2f',borderRadius: '0px 0px 8px 8px', }}
                                onClick={() => handleChangeImageClick("next")}
                            >
                                <FontAwesomeIcon icon={faAngleDown} size="2x"/>
                            </div>
                        </div>
                    </TabPanel>
                </Box>
               
            </section>
        </div>
    );
};

export default VerticalCarouselMobile;