import { Col, Row } from "react-bootstrap";
import * as React from "react";
import { useParams } from "react-router";
import initCornerstone from '../initCornerstone';
import { DndProvider } from "react-dnd";
import ListSeries from "../components/Radiology/ListSeries";
import { RenderCathlab } from "../components/Radiology/RenderCathlab";
import { HTML5Backend } from "react-dnd-html5-backend";
import { SpinnerLoader } from "../components/SpinnerLoader";
import axios from 'axios';





const LeftContainerStyle = {
    height: '100vh',
    width: '98%',
    background: '#000',
    padding: '20px',
    overflow: 'auto',
    boderRight: '1px solid #FFF'
}



const ShowCathLab = (props: any) => {
    const locationObj = useParams();
    const [loading, setLoading] = React.useState(true);
    const [series, setSeries] = React.useState([] as any);
    const [activeSeries, setActiveSeries] = React.useState(0);
    const { centerid, instanceid }: any = locationObj;

    const groupBy = (key: string, array: any) =>
        array.reduce(
            (objectsByKeyValue: any, obj: any) => ({
                ...objectsByKeyValue,
                [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
            }),
            {}
        );

    React.useEffect(() => {
        initCornerstone();
        try {
            (async () => {
                const obj = await axios.post('https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod/radiology-studies/by-hash/' + centerid, {
                    "studyHash": instanceid
                });

                const { filteredList } = obj.data;
                const fileList = groupBy('series', filteredList[0].urls);
                const series: any = [];
                Object.keys(fileList).forEach((key, inex) => {
                    series.push(fileList[Number(key)]);
                });
                setSeries([...series]);
                setLoading(false);
            })()

        } catch (e) {
            console.log(e);
        }
    }, [])

    if (loading) {
        return <div style={{ width: '100vw', height: '100vh', backgroundColor: '#000' }}>  <div style={{ padding: '20px' }}><SpinnerLoader message={'Preparing viewer, Please wait...'} /> </div></div>;
    }
    return <Row>
        <DndProvider backend={HTML5Backend}>
            <Col xs={2}>
                <div style={LeftContainerStyle}>
                    <div><img style={{ width: '200px', height: '50px' }} src={'https://pdf-logo.s3.ap-south-1.amazonaws.com/Bewell-radiology-logo-blue.png'} alt='logo'></img></div>
                    <hr style={{ color: '#84AED4'}}/>
                    {series.map((seriesImages: any, index: number) => {
                        const images = seriesImages.map((item: any) => item.url);
                        return <ListSeries activeSeries={activeSeries} setActiveSeries={setActiveSeries} index={index} seriesNumber={index + 1} imageIds={images} totalImages={images.length} key={index} prefetch={true}/>
                    })}
                </div>
            </Col>
            <Col xs={10}>
                <Row>
                    <RenderCathlab  imageIds={series[activeSeries].map((item: any) => item.url)} />
                </Row>
            </Col>
        </DndProvider>
    </Row>
}

export default (ShowCathLab);

