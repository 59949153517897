import * as React from "react";
import measurementIcon from "../../images/radiology-tools/svg/measure.svg";
import angleIcon from "../../images/radiology-tools/svg/angle.svg";
import rotateIcon from "../../images/radiology-tools/svg/rotate.svg";
import bidirectionIcon from "../../images/radiology-tools/svg/bidirectional.svg";
import invertIcon from "../../images/radiology-tools/svg/invert.svg";
import eraserIcon from "../../images/radiology-tools/svg/eraser.svg";
import wwwcIcon from "../../images/radiology-tools/svg/wwwc.svg";
import magnifyIcon from "../../images/radiology-tools/svg/magnify.svg";
import panIcon from "../../images/radiology-tools/pan.png";
import downloadIcon from "../../images/radiology-tools/svg/download.svg";
import layoutSelect from "../../images/radiology-tools/svg/layout.svg";
import cobbAngle from "../../images/radiology-tools/svg/cobb-angle.svg";
import stackIcon from "../../images/radiology-tools/svg/stack.svg";
import hFlipIcon from "../../images/radiology-tools/svg/h-flip.svg";
import vFlipIcon from "../../images/radiology-tools/svg/v-flip.svg";
import canvasToImage from 'canvas-to-image'
import {
  faComment,
  faArrowsAltV,
  faArrowsAltH,
  // faCircleNotch,
  // faCrosshairs, 
  faRulerCombined,
  faBalanceScale,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus, faLayerGroup, faRuler, faEraser,faChevronCircleLeft} from "@fortawesome/free-solid-svg-icons";
import { DcmViewerTools } from "../../constants/Radiology";

const filteredToolList = DcmViewerTools.filter((tool: any) => tool.id);

export const ToolsList = (props: {
  activeTool: string;
  selectActiveTool: Function;
  viewportAction: Function;
  layout: number;
  setLayout: Function;
  downloadImage: Function;
}) => {
  const { activeTool, selectActiveTool, viewportAction, layout, setLayout, downloadImage} = props;

  const changeLayout = (layout: number)=>{
    setLayout(layout);
  }
  
  return (
    <div className={"dcm-tool-list1"}>
      {filteredToolList.map((tool: any, index: number) => {
        let imgIcon = "";
        let fontIcon: any = "";
        switch (tool.id) {
          case "measurement":
            imgIcon = measurementIcon;
            break;
          case "angle":
            imgIcon = angleIcon;
            break;
          case "cobb":
            imgIcon = cobbAngle;
            break;
          case "rotate":
            imgIcon = rotateIcon;
            break;
          case "v-flip":
            imgIcon = vFlipIcon;
            break;
          case "h-flip":
            imgIcon = hFlipIcon;
            break;
          case "probetool":
            fontIcon = faEye;
            break;
          case "zoom":
            imgIcon = magnifyIcon;
            break;
          case "wwwc":
            imgIcon = wwwcIcon;
            break;
          case "pan":
            imgIcon = panIcon;
            break;
          case "bidirectional":
            imgIcon = bidirectionIcon;
            break;
          case "invert":
            imgIcon = invertIcon;
            break;
          case "eraser":
            imgIcon = eraserIcon;
            break;
          case "arrowannotate":
            fontIcon = faComment;
            break;
          case "magnify":
            imgIcon = magnifyIcon;
            break;

          case "download":
            imgIcon = downloadIcon;
            break;

          case "stackScroll": {
            imgIcon = stackIcon;
            break;
          }
          /*case "layout": {
            imgIcon = layoutSelect;
            break;
          } */
        }
        return (
          <div
            style={{marginBottom:'3vh',marginLeft:0,display:'flex',alignItems: 'center',justifyContent:'center'}}
            key={"tool-" + index}
            className={
              "dcm-tool1" + (activeTool === tool.name ? " active-tool" : "")
            }
            onClick={() => {
              if(activeTool === 'Download'){
                selectActiveTool('Download')
                return;
              } 
              if(activeTool === 'Layout') {
                selectActiveTool('StackScrollMouseWheel');
                return;
              } 
              if (tool.id === 'download') {
                downloadImage('viewport-element', 'png', imgIcon);
            }
              if (tool.viewportAction) {
                viewportAction(tool.viewportAction);
                return;
              }
              selectActiveTool(tool.name);
            }}
          >
            <div >
              {imgIcon ? (
                <>
                  <img src={imgIcon}  style={{width: '4vw'}} alt={"tool-img-" + index} className={(tool.id === 'layout' || tool.id === 'download' ? 'layout-selector' : 'no-cls') + ' img-tool '+tool.name}/>
                  {
                    activeTool === 'Layout' && tool.id ==='layout' ? <div className={'layout-selector-dropdown'}>
                      <div className={'layout-selector-dropdown-content'}>
                          <div className={'layout-dd-elem' + ( layout === 1 ? ' selected' : '')} onClick={()=> changeLayout(1)}> 1</div>
                          <div className={'layout-dd-elem' + ( layout === 2 ? ' selected' : '')} onClick={()=> changeLayout(2)}>1 : 1</div>
                           <div className={'layout-dd-elem' + ( layout === 4 ? ' selected' : '')} onClick={()=> changeLayout(4)}>2 : 2</div>
                      </div>
                    </div> : null 
                  }
                </>
              ) : (
                <FontAwesomeIcon icon={fontIcon} color={"#FFF"}  style={{ fontSize: '3.5vw'}} />
              )}
            </div>
            {<div style={{ fontSize: '1.5vw'}}>{tool.displayName}</div> }
          </div>

        );
      })}
    </div>
  );
};