import React, { useRef } from 'react';
import measurementIcon from "../../images/radiology-tools/svg/measure.svg";
import angleIcon from "../../images/radiology-tools/svg/angle.svg";
import rotateIcon from "../../images/radiology-tools/svg/rotate.svg";
import bidirectionIcon from "../../images/radiology-tools/svg/bidirectional.svg";
import invertIcon from "../../images/radiology-tools/svg/invert.svg";
import eraserIcon from "../../images/radiology-tools/svg/eraser.svg";
import wwwcIcon from "../../images/radiology-tools/svg/wwwc.svg";
import magnifyIcon from "../../images/radiology-tools/svg/magnify.svg";
import panIcon from "../../images/radiology-tools/pan.png";
import downloadIcon from "../../images/radiology-tools/svg/download.svg";
import layoutSelect from "../../images/radiology-tools/svg/layout.svg";
import cobbAngle from "../../images/radiology-tools/svg/cobb-angle.svg";
import stackIcon from "../../images/radiology-tools/svg/stack.svg";
import hFlipIcon from "../../images/radiology-tools/svg/h-flip.svg";
import vFlipIcon from "../../images/radiology-tools/svg/v-flip.svg";
import Popup from 'reactjs-popup';

import {
    faComment,
    faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DcmViewerTools,DcmViewerToolsMobile,PortraitTools } from "../../constants/Radiology";

const filteredToolList = DcmViewerTools.filter((tool: any) => tool.id);
const filteredMobileTools = DcmViewerToolsMobile.filter((tool: any) => tool.id);
const filteredPortraitTools = PortraitTools.filter((tool: any) => tool.id);

export const ToolsListMobile = (props: {
    activeTool: string;
    selectActiveTool: Function;
    viewportAction: Function;
    layout: number;
    setLayout: Function;
    downloadImage: Function;
  fromReporting?:boolean;
}) => {
  const { activeTool, selectActiveTool, viewportAction, layout, setLayout, downloadImage, fromReporting } = props;

    const changeLayout = (layout: number) => {
        setLayout(layout);
    }

    return (
      <div>
        <div className={"dcm-tool-list"} style={fromReporting ? {justifyContent: 'flex-start', gap: '5px', marginLeft: '20px'} : {}}>
            {filteredPortraitTools.map((tool: any, index: number) => {
                let imgIcon = "";
                let fontIcon: any = "";
                switch (tool.id) {
                    case "measurement":
                      imgIcon = measurementIcon;
                      break;
                    case "zoom":
                      imgIcon = magnifyIcon;
                      break;
                    case "wwwc":
                      imgIcon = wwwcIcon;
                      break;
                    case "probetool":
                        fontIcon = faEye;
                        break;
                    case "magnify":
                      imgIcon = magnifyIcon;
                      break;
                  }
                return (
                    <div
                        //style={{justifyContent:'center',textAlign:'center',}}
                        key={"tool-" + index}
                        className={
                            "dcm-tool-horizontal" + (activeTool === tool.name ? " active-tool" : "")
                        }
                        onClick={() => {
                            console.log(activeTool)
                            if (tool.viewportAction) {
                                viewportAction(tool.viewportAction);
                                return;
                            }
                            selectActiveTool(tool.name);
                        }}
                    >
                        <div style={{padding:5,justifyContent:'center',textAlign:'center'}}>
                            {imgIcon ? (
                                <>
                                  <img src={imgIcon} style={{ height:'22px',color:'black',fill: 'green',}} alt={"tool-img-" + index} className={(tool.id === 'layout' || tool.id === 'download' ? 'layout-selector' : 'no-cls') + ' img-tool ' + tool.name}/>
                                </>
                            ) : (      
                                  <FontAwesomeIcon icon={fontIcon} style={{ height:'22px',}} color={'#fff'} size={"lg"} /> 
                            )}
                        </div>
                        { <div style={{fontSize: '12px',fontWeight:'600',color:activeTool === tool.name ? '#84AED4'  : '#fff'}}>
                            { tool.displayName }
                        </div> }
                    </div>
                );
            })}
          <div>
          <Popup
            position="top right"
            trigger={
            <div>
              <div style={{justifyContent:'right',alignItems:'center',marginTop:5}}>
                  <img src={stackIcon} style={{ height:'22px',color:'black',fill: 'green',paddingLeft:'20px'}} alt={'tools'}/>
                  <div style={{fontSize: '12px',fontWeight:'600',color:'#fff',marginTop:5}}>
                    More Tools 
                  </div>
              </div>
            </div>
          }
          >
            <div style={{  backgroundColor:'#000',color:'#FFF',border: '1px solid #FFF',borderRadius: '10px',marginLeft:30}}>
            <div className={"dcm-tool-list2"} style={fromReporting ? {justifyContent: 'flex-start', gap: '5px', marginLeft: '20px'} : {}}>
              {filteredMobileTools.map((tool: any, index: number) => {
                  let imgIcon = "";
                  let fontIcon: any = "";
                  switch (tool.id) {
                      case "measurement":
                        imgIcon = measurementIcon;
                        break;
                      case "angle":
                        imgIcon = angleIcon;
                        break;
                      case "cobb":
                        imgIcon = cobbAngle;
                        break;
                      case "rotate":
                        imgIcon = rotateIcon;
                        break;
                      case "v-flip":
                        imgIcon = vFlipIcon;
                        break;
                      case "h-flip":
                        imgIcon = hFlipIcon;
                        break;
                      case "probetool":
                        fontIcon = faEye;
                        break;
                      case "zoom":
                        imgIcon = magnifyIcon;
                        break;
                      case "wwwc":
                        imgIcon = wwwcIcon;
                        break;
                      case "pan":
                        imgIcon = panIcon;
                        break;
                      case "bidirectional":
                        imgIcon = bidirectionIcon;
                        break;
                      case "invert":
                        imgIcon = invertIcon;
                        break;
                      case "eraser":
                        imgIcon = eraserIcon;
                        break;
                      case "arrowannotate":
                        fontIcon = faComment;
                        break;
            
                      case "download":
                        imgIcon = downloadIcon;
                        break;

                      case "layout": 
                        imgIcon = layoutSelect;
                        break;

                      case "stackScroll": {
                        imgIcon = stackIcon;
                        break;
                      }
                      
                      
                    }
                  return (
                      <div
                          style={{width:'65px',height:'55px',display:'flex',flexDirection:'column',alignItems:'center',}}
                          key={"tool-" + index}
                          className={
                              "dcm-tool-horizontal" + (activeTool === tool.name ? " active-tool" : "")
                          }
                          onClick={() => {
                              console.log(activeTool)
                              if (activeTool === 'Download'){
                                  selectActiveTool('Download');
                                  return;
                              }
                              if(activeTool === 'Download' || activeTool === 'Layout') {
                                  selectActiveTool('StackScrollMouseWheel');
                                  return;
                              }
                              if (tool.id === 'download') {
                                  downloadImage('viewport-element', 'png', imgIcon);
                              }
                              if (tool.viewportAction) {
                                  viewportAction(tool.viewportAction);
                                  return;
                              }
                              selectActiveTool(tool.name);
                          }}
                      >
                          <div style={{justifyContent:'center',alignItems:'center'}}>
                              {imgIcon ? (
                                  <>
                                      <img src={imgIcon} style={{ height:'22px',color:'black',fill: 'green',}} alt={"tool-img-" + index} className={(tool.id === 'layout' || tool.id === 'download' ? 'layout-selector' : 'no-cls') + ' img-tool ' + tool.name}/>
                                  </>
                              ) : (      
                                      <FontAwesomeIcon icon={fontIcon} style={{ height:'22px',}} color={'#fff'} size={"lg"} /> 
                              )}
                          </div>
                          { <div style={{fontSize: '65%',fontWeight:'600',marginTop:5,color:activeTool === tool.name ? '#84AED4'  : '#fff'}}>
                              {
                                tool.displayName
                              }
                          </div> }
                      </div>
                  );
              })}      
          </div>
          </div>
        </Popup>
      </div>      
         </div>
        
    </div>
    );
};
