import axios from "axios";
import { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useParams } from "react-router";
import Dicomportlet from "../components/Radiology/Dicomportlet";
import { SpinnerLoader } from "../components/SpinnerLoader";
import initCornerstone from "../initCornerstone";
import cornerstone from 'cornerstone-core';
const ResponsiveGridLayout = WidthProvider(Responsive);


const CathLabGridLayout = () => {
    const locationObj = useParams();
    const [loading, setLoading] = useState(true);
    const [series, setSeries] = useState([] as any);
    const [activeSeries, setActiveSeries] = useState(0);
    const { centerid, instanceid }:any = locationObj;

    const groupBy = (key: string, array: any) =>
        array.reduce(
            (objectsByKeyValue: any, obj: any) => ({
                ...objectsByKeyValue,
                [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
            }),
            {}
        );


    useEffect(() => {
        initCornerstone();
        try {
            (async () => {
                const obj = await axios.post('https://q1lsdba6f6.execute-api.ap-south-1.amazonaws.com/prod/radiology-studies/by-hash/' + centerid, {
                    "studyHash": instanceid
                });

                const { filteredList } = obj.data;
                const fileList = groupBy('series', filteredList[0].urls);
                const series: any = [];
                Object.keys(fileList).forEach((key, inex) => {
                    series.push(fileList[Number(key)]);
                });
                let loadedImages = [];
                setSeries([...series]);
                // if (series.length) {
                //     const listOfPromises = series.map(async (ser: any ,index: number) => {
                //         const promise = await Promise.all(ser.map(async (imageUrl: any) => {
                //             const image = await cornerstone.loadAndCacheImage(imageUrl.url);
                //             loadedImages.push(imageUrl.url);
                //             return image;
                //         }));
                //         if (index === 0) {
                //             setLoading(false);
                //         }
                //         return promise;
                //     });
                // }
                setLoading(false);
               
            })()


        } catch (e) {
            console.log(e);
        }
    }, []);

    useEffect(() => {
        let loadedImages: any = [];
        
    }, [series]);

    if (loading) {
        return <SpinnerLoader />
    }

    return (
        <div style={{background: '#000'}}>
            {/* <ResponsiveGridLayout
                className="layout" cols={{ "lg": 12, "sm": 12, "xs": 12, "xxs": 12, "md": 12 }} margin={[0, 0]}
                autoSize={false} allowOverlap={false}> */}
                {/* <div key="a" data-grid={{ x: 0, y: 0, w: 12, h: 5, static: true }} style={{background: 'black'}} > */}
            <Dicomportlet imageIds={series[activeSeries].map((item: any) => item.url)} series={series} activeSeries={activeSeries} setActiveSeries={setActiveSeries}/>
                {/* </div> */}
            {/* </ResponsiveGridLayout> */}
        </div>
    );
}

export default CathLabGridLayout;

