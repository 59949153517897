import React, {  useMemo } from "react";


export const Appcontext = React.createContext(null as any);



export const Appcontextprovider = (props: any) => {

    const contextValue = useMemo(
        () => ({
           
        }),
        [],
    );


    return (
        <Appcontext.Provider
            value={contextValue}
        >
            {props.children}
        </Appcontext.Provider>
    );
};

export default Appcontextprovider;
